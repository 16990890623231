import React, {Component} from 'react'
import {Button, Checkbox, Divider, Form, Grid, Header, Icon, Progress, Segment} from 'semantic-ui-react'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Fade from 'react-reveal/Fade'
import {NavLink} from 'react-router-dom'

class Import extends Component {
    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
        this.state = {
            percent: 0,
            submitted: false,
            requestId: '',
            status: '',
            show: false,
            data: [],
            dataLoaded: true,
            loadStats: false,
            projectName: '',
            elementName: '',
            formData: {},
            errored:"error"
        };

        this.fileReader.onload = (event) => {
            this.setState({jsonFile: JSON.parse(event.target.result)}, () => {
                this.importConfiguration(this.state.jsonFile);
                this.setState({percent: 15, status: 'FILES UPLOADED'})
            });
            this.setState({data: this.state.jsonFile});
            this.setState({show: true});
            this.setState({loadStats: true})
        }
    }

    componentDidMount() {
        this.setState({projectName: this.props.projectName})
    }

    getStats = (data) => {
        if (this.state.loadStats) {
            const numOfBlocks = data.blocks.length;
            let _blocks = 'blocks';
            let _houses = 'houses';
            let housesPerBlock = [];
            let numOfHouses = [];

            for (let i = 0; i < numOfBlocks; i++) {
                // If length equals 1 then no plural word
                if (data.blocks[i].houses.length === 1) {
                    _houses = 'house'
                }

                housesPerBlock.push(
                    <React.Fragment key={data.name}>
                        <li>{`Block ${data.blocks[i].name} has ${data.blocks[i].houses.length} ${_houses}.`}</li>
                    </React.Fragment>,
                )
                numOfHouses.push(data.blocks[i].houses.length)
            }
            if (numOfBlocks === 1) {
                _blocks = 'block'
            } else {
                _blocks = 'blocks'
            }

            let totalBlocks = `This file contains ${numOfBlocks} ${_blocks}.`;

            if (Math.max(...numOfHouses) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }
            let maxHouses = `Each block has a maximum number of ${Math.max(...numOfHouses)} ${_houses} per row.`;

            if (Math.min(...numOfHouses) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }
            let minHouses = `Each block has a minimum number of ${Math.min(...numOfHouses)} ${_houses} per row.`;

            if (numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }

            let totalHouses = `This file contains a total number of ${numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0)} ${_houses}.`;
            let blockStats = {totalBlocks, housesPerBlock, minHouses, maxHouses, totalHouses};
            let stats = [{blockStats}];
            return stats
        }
    };

    handleChange = (e, {name, value}) => {
        if(isNaN(value)){
            alert("Please enter a number")
        }
        else if (value === " "){
            alert("this is set to 0");
        }
        else {
            this.setState({[name]: value});
        }
    };

    handleSubmit = () => {

        let self = this;

        const {name, positionX, positionY, positionZ, rotationX, rotationY, rotationZ} = this.state;
        let position = {"x": positionX, "y": positionY, "z": positionZ};
        let rotation = {"x": rotationX, "y": rotationY, "z": rotationZ};
        let formObject = {"project": this.state.projectName, "name": name, "position": position, "rotation": rotation};

        let formData = new FormData();
        formData.append("element", JSON.stringify(formObject));

        const file = document.querySelector('input[type="file"]').files[0];
        formData.append("file", file);

        fetch(process.env.REACT_APP_API_BASE_URL + "/element", {
            method: 'POST',
            body: formData
        }).then(function (response) {
            if (!response.ok) {
                response.json().then(function (object) {
                    console.log(object);
                    console.log(object.propertyErrors);
                    self.setState({formError: object.propertyErrors})
                });
                throw new Error(response.statusText);
            }
            self.setState({success: true});
            console.log(response)
        }).catch(error => console.log(error));
    };

    render() {
        // Returns parsed data from the getStats function
        const parsedData = this.getStats(this.state.data);
        //Creates function which maps the stats
        const showStats = () => {
            if (this.state.loadStats) {
                return <div>{parsedData.map((d) => {
                    return <Fade key={this.state.data.name} duration={2000} when={this.state.show}>
                        <ul className="list-items-stats">
                            <li>{d.blockStats.totalBlocks}</li>
                            {d.blockStats.housesPerBlock}
                            <li>{d.blockStats.totalHouses}</li>
                            <li>{d.blockStats.minHouses}</li>
                            <li>{d.blockStats.maxHouses}</li>
                        </ul>
                    </Fade>
                })}
                </div>
            }
        };

        return (
            <div className="white">
                <Grid verticalAlign='middle' stackable textAlign='center'>
                    <Grid.Row style={{height: '60vh'}}>

                        <Grid.Column style={{maxWidth: '800px'}}>

                            <Segment className="drop-shadow">
                                <h2>Create new element</h2>

                                <Form method="POST" encType="multipart/form-data">
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid name="elementName" label='Element Name'
                                                    placeholder='Element Name'  onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input name="positionX" fluid label='X-position' placeholder=''
                                                    onChange={this.handleChange}/>
                                        <Form.Input name="positionY" fluid label='Y-position' placeholder=''
                                                    onChange={this.handleChange}/>
                                        <Form.Input name="positionZ" fluid label='Z-position' placeholder=''
                                                    onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input name="rotationX" fluid label='X-rotation' placeholder=''
                                                    onChange={this.handleChange}/>
                                        <Form.Input name="rotationY" fluid label='Y-rotation' placeholder=''
                                                    onChange={this.handleChange}/>
                                        <Form.Input name="rotationZ" fluid label='Z-rotation' placeholder=''
                                                    onChange={this.handleChange}/>
                                    </Form.Group>

                                    {/*<Files*/}
                                    {/*    className="files-dropzone white"*/}
                                    {/*    onChange={file => {*/}
                                    {/*        this.fileReader.readAsText(file[0])*/}
                                    {/*    }}*/}
                                    {/*    onError={err => console.log(err)}*/}
                                    {/*    accepts={['.rvt']}*/}
                                    {/*    multiple*/}
                                    {/*    maxFiles={3}*/}
                                    {/*    maxFileSize={10000000}*/}
                                    {/*    minFileSize={0}*/}
                                    {/*    clickable*/}
                                    {/*>*/}
                                    <Segment placeholder>
                                        <Header as='h4' icon>
                                            <Icon name='file outline'/>
                                            Import JSON
                                        </Header>
                                        <label>.rvt Upload</label><input
                                        accept=".rvt"
                                        type="file" name="file"></input>
                                    </Segment>
                                    {/*</Files>*/}
                                    <Divider/>

                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Checkbox label='.rvt file' defaultChecked/>
                                        </Form.Field>
                                    </Form.Group>

                                </Form>

                                <div style={{display: this.state.percent === 0 ? 'none' : ''}}>
                                    <Divider/>
                                    <Progress percent={this.state.percent} label={this.state.status} indicating/>
                                    <Divider/>
                                </div>

                                <div style={{display: this.state.percent === 100 ? '' : 'none'}}>
                                    <Button
                                        onClick={() => this.getExport(this.state.requestId, 'rvt-collection', 'zip')}
                                        secondary
                                    >Download</Button>
                                </div>

                                <Message hidden={!this.state.submitted}>Configuration submitted with
                                    requestId <b>{this.state.requestId}</b> </Message>
                                <Message icon>

                                    <Message.Content>
                                        <Message.Header>Configuration stats</Message.Header>
                                        {showStats()}
                                    </Message.Content>
                                </Message>
                            </Segment>
                            <div style={{
                                display: 'flex', flexDirection: 'row', paddingTop: '50px',
                                maxWidth: '800px', margin: 'auto', justifyContent: 'space-evenly',
                            }}>
                                {this.state.dataLoaded && this.state.elementName !== '' ?
                                    (
                                        <div>
                                            <Button size={'big'} onClick={this.handleSubmit}>Submit Element</Button>
                                            <NavLink to="/choice"><Button size={'big'}>Return to choice screen</Button></NavLink>
                                        </div>

                                    ) :
                                    (<div>
                                            <Button size={'big'} disabled>Submit Element</Button>
                                            <NavLink to="/choice"><Button size={'big'}>Return to choice screen</Button></NavLink>
                                        </div>
                                    )
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>

        )
    }
}

export default Import
