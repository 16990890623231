import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Spinner extends Component {
    render() {
        return (
            <div>
                <div className="lazy-loader"> </div>
            </div>
        );
    }
}


export default Spinner;
