import React, {Component} from 'react'
import Fade from 'react-reveal/Fade'
import {Button, Grid, Icon, Message, Modal, Pagination, Segment, Table, Label} from 'semantic-ui-react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Spinner from "./Spinner";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            // pageNumber: this.props.pageNumber,
            totalPageNumber: 0,
            configurations: {},
            time: Date.now(),
            loading: true,
            loadingEntries: true,
            currentId: '',
            configuration: [],
            information: '',
            openInformation: false,
            exception: '',
            uploadError_Message: [],
            configurationError_Message: {},
            statsArray: [],
            json: '',
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.setState({loadingEntries: true});
        this.setState({projectName: this.props.projectName});

        this.fetchConfig();
        this.interval = setInterval(() => {
            this.fetchConfig();
            return this.setState({time: Date.now()})
        }, 5000);
        this.setState({loading: false});
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    fetchConfig() {
        let self = this;

        let data = {
            "pageNumber":1,
            "project": "spam"
        };

        fetch(process.env.REACT_APP_API_BASE_URL + "/element/page", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        }).then(function (response) {
            if (!response.ok) {
                response.json().then(function (object) {
                    console.log(object);
                    console.log(object.propertyErrors);
                    self.setState({formError: object.propertyErrors})
                });
                throw new Error(response.statusText);
            }
            self.setState({success: true});
            console.log(response)
        }).catch(error => console.log(error));


    }

    handleRetry = (id, exportRevitFile, asNewRequest) => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/${id}/retry`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: id, exportRevitFile: exportRevitFile, asNewRequest: asNewRequest}),
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
        }).catch(error => console.log(error));

        this.closeRetryModal();
        this.fetchConfig()
    };

    handleSkip = (id, skipMessage) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/' + id + '/skip', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: id, message: skipMessage}),
        }).then(function (response) {
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
        }).catch(error => console.log(error));

        console.log('skipped')
        ;
        this.closeSkipModal();
        this.fetchConfig()
    };

    getExport = (id, projectName, fileType, extension) => {
        window.open(process.env.REACT_APP_AZURE_BLOB_URL + `/${fileType}/${projectName}_${id}${extension}`, '_blank');
        window.focus()
    };

    state = {openRetryModal: false, id: '', exportRevitFile: '', asNewRequest: '', message: ''};

    showRetryModal = (id, exportRevitFile, asNewRequest, message) => () => this.setState({
        id: id,
        exportRevitFile: exportRevitFile,
        asNewRequest: asNewRequest,
        message: message,
        openRetryModal: true,
    });
    closeRetryModal = () => this.setState({openRetryModal: false});

    showSkipModal = (id, skipMessage, message) => () => this.setState({
        id: id,
        skipMessage: skipMessage,
        message: message,
        openSkipModal: true,
    });
    closeSkipModal = () => this.setState({openSkipModal: false});

    showRequestModal = (request) => {
        this.setState({
            json: JSON.stringify(request, null, 4),
            showRequest: true,
        })
    };
    closeRequestModal = () => this.setState({showRequest: false});

    handlePaginationChange = (e, {activePage}) => {
        window.location.href = '/dashboard/' + activePage;
        return this.setState({activePage})
    };

    showInfo = (id) => {
        this.setState({
            currentId: id,
            informationState: 'info',
            openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                this.state.informationState !== 'info',
        });
        this.fetchOptions(id, this.printInfo, 'options')
    };

    showUploadError = (id) => {
        this.setState({
            currentId: id,
            informationState: 'uploadError',
            openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                this.state.informationState !== 'uploadError',
        });
        this.fetchOptions(id, this.printUploadError, 'upload-exception')
    };

    showConfigurationError = (id) => {
        this.setState({
            currentId: id,
            informationState: 'configurationError',
            openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
                this.state.informationState !== 'configurationError',
        });
        this.fetchOptions(id, this.printConfigurationError, 'configuration-exception');
    };

    popUpInfo = (id) => {
        this.fetchOptions(id, this.showRequestModal, 'options')
    };

    fetchOptions = (id, _callback, endpoint) => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/${id}/${endpoint}`,
        ).then((response) => response.json()).then((data) => {
                _callback(data);
            },
        ).catch((error => console.log(error)));
    };

    printInfo = (info) => {
        this.setState({statsArray: this.getStats(info)})
    };

    printUploadError = (info) => {
        this.setState({uploadError_Message: info})
    };

    printConfigurationError = (info) => {
        this.setState({configurationError_Message: info}, () => {
        })
    };

    getStats = (data) => {
        const numOfBlocks = data.blocks.length;
        let _blocks = 'blocks';
        let _houses = 'houses';
        let housesPerBlock = [];
        let numOfHouses = [];

        if (data.blocks.length <= 0) {
            return null
        } else {
            for (let i = 0; i < numOfBlocks; i++) {
                // If length equals 1 then no plural word
                if (data.blocks[i].houses.length === 1) {
                    _houses = 'house'
                }

                housesPerBlock.push(
                    <React.Fragment key={data.name}>
                        <Message.Item>{`Block ${data.blocks[i].name} has ${data.blocks[i].houses.length} ${_houses}.`}</Message.Item>
                    </React.Fragment>,
                );
                numOfHouses.push(data.blocks[i].houses.length)
            }
            if (numOfBlocks === 1) {
                _blocks = 'block'
            } else {
                _blocks = 'blocks'
            }

            let totalBlocks = `This file contains ${numOfBlocks} ${_blocks}.`;

            if (Math.max(...numOfHouses) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }
            let maxHouses = `Each block has a maximum number of ${Math.max(...numOfHouses)} ${_houses} per row.`;

            if (Math.min(...numOfHouses) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }
            let minHouses = `Each block has a minimum number of ${Math.min(...numOfHouses)} ${_houses} per row.`;

            if (numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0) === 1) {
                _houses = 'house'
            } else {
                _houses = 'houses'
            }

            let totalHouses = `This file contains a total number of ${numOfHouses.reduce(
                (partial_sum, a) => partial_sum + a, 0)} ${_houses}.`;

            let blockStats = [totalBlocks, housesPerBlock, minHouses, maxHouses, totalHouses];

            return blockStats
        }
    };

    render() {
        const {openRetryModal, totalPageNumber, openSkipModal, showRequest, pageNumber, configurations} = this.state;
        return <div className="dashboard">
            <Segment>
                <Button primary style={{margin: '0.5em'}} onClick={() => this.fetchConfig()}> REFRESH (Last
                    refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>
                <Grid.Column>
                    <Pagination
                        activePage={pageNumber}
                        onPageChange={this.handlePaginationChange}
                        totalPages={totalPageNumber}
                        siblingRange={8}
                    />
                </Grid.Column>
            </Segment>

            <div>
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine>ID</Table.HeaderCell>
                            <Table.HeaderCell>Project Name</Table.HeaderCell>
                            <Table.HeaderCell>Duration</Table.HeaderCell>
                            <Table.HeaderCell>Request date</Table.HeaderCell>
                            <Table.HeaderCell>Request</Table.HeaderCell>
                            <Table.HeaderCell>Exports</Table.HeaderCell>
                            <Table.HeaderCell singleLine>Actions</Table.HeaderCell>
                            <Table.HeaderCell>Configuration Status</Table.HeaderCell>
                            <Table.HeaderCell>Upload Status</Table.HeaderCell>
                            <Table.HeaderCell>Source</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {this.state.loadingEntries ?
                        (<Spinner/>) :
                        (<Table.Body>
                            {Object.keys(configurations).map((keyName, i) => {
                                let item = configurations[keyName];

                                return <React.Fragment>
                                    <Table.Row
                                        positive={!item.thrownUploadException && !item.thrownConfigurationException
                                        && item.duration !== 0}
                                        negative={item.thrownUploadException ||
                                        item.thrownConfigurationException}
                                        className={item.thrownConfigurationException ? 'heavy-error' : ''}
                                        key={item.id}>
                                        <Table.Cell nonBreaking>
                                            <div onClick={() => this.showInfo(item.id)}
                                                 style={{
                                                     display: 'flex',
                                                     flexDirection: 'row',
                                                     justifyContent: 'left',
                                                     cursor: 'pointer',
                                                 }}>
                                                <Icon className="stats-icon" name="info"/>
                                                {item.id}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.projectName}
                                        </Table.Cell>
                                        {item.exceptionThrown ?
                                            <Table.Cell>
                                                <Icon name='attention'/>
                                                {item.duration}
                                            </Table.Cell>
                                            :
                                            <Table.Cell>
                                                {item.duration}
                                            </Table.Cell>}

                                        <Table.Cell>
                                            {item.created}
                                        </Table.Cell>

                                        <Table.Cell>
                                            <Button.Group>
                                                <Button
                                                    onClick={() => this.popUpInfo(item.id)}><Icon
                                                    name='magnify'/></Button>
                                                <CopyToClipboard text={item.options}
                                                                 onCopy={() => this.setState({copied: true})}>
                                                    <Button><Icon name='clipboard outline'/></Button>
                                                </CopyToClipboard>
                                            </Button.Group>
                                        </Table.Cell>

                                        <Table.Cell singleLine>

                                            {item.exports.indexOf('archive_zip') === -1 ? (null) :
                                                (<div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer"
                                                }}>
                                                    <div className={'centered-button'}>
                                                        <div className="hit-box-container">
                                                            <Button className="hitbox download"
                                                                    positive
                                                                    onClick={() => this.getExport(item.id, item.projectName, 'archive-rvt',
                                                                        '.zip')}> <Icon style={{margin: "auto"}}
                                                                                        color="black"
                                                                                        name="download"/>
                                                            </Button>
                                                            <Label className="info-box download"
                                                                   pointing='below' color="grey">
                                                                Download entry
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </div>)}
                                        </Table.Cell>

                                        <Table.Cell singleLine className="important-buttons">
                                            <div className="hit-box-container">
                                                <Button color="orange" className="hitbox skip"
                                                        onClick={this.showSkipModal(item.id, 'SkippedThroughDashboard',
                                                            'Are you sure you want to skip this export?')}>
                                                    <Icon style={{marginRight: "5px"}} name='step forward'/>
                                                </Button>

                                                <Label className="info-box skip" color="grey" pointing='below'>
                                                    Skip entry
                                                </Label>
                                            </div>

                                            <div className="hit-box-container">
                                                <Button primary className="hitbox redo"
                                                        onClick={this.showRetryModal(item.id, false, false,
                                                            'Are you sure you want to retry this export without a revit file? This will update the files displayed on any dashboards linked to the database, which may result in a signed contract being updated')}>
                                                    <Icon style={{marginRight: "5px"}} name='sync'/>
                                                </Button>
                                                <Label className="info-box redo" color="grey" pointing='below'>
                                                    Redo entry
                                                </Label>
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell>
                                            {item.thrownConfigurationException &&
                                            <div className={'centered-button'}>
                                                <div className="hit-box-container">

                                                    <Button className="hitbox configurationError"
                                                            negative onClick={() => {
                                                        this.showConfigurationError(item.id)
                                                    }}><Icon name='info'/>
                                                    </Button>
                                                    <Label color="grey" className="info-box configurationError"
                                                           pointing='below'>
                                                        Click to see configuration errors
                                                    </Label>
                                                </div>
                                            </div>
                                            }
                                        </Table.Cell>

                                        <Table.Cell>
                                            {item.thrownUploadException &&
                                            <div className={'centered-button'}>
                                                <div className="hit-box-container">

                                                    <Button className="hitbox uploadError"
                                                            negative onClick={() => {
                                                        this.showUploadError(item.id)
                                                    }}><Icon name='info'/>
                                                    </Button>
                                                    <Label className="info-box uploadError" color="grey"
                                                           pointing='below'>
                                                        Click to see upload errors
                                                    </Label>
                                                </div>
                                            </div>
                                            }
                                        </Table.Cell>

                                        <Table.Cell>
                                            {item.source}
                                        </Table.Cell>
                                    </Table.Row>

                                    {/*/Uses quotation marks to set display to its original value. */}
                                    {/* Other values flex, block etc makes the error message small ( 1 col wide)*/}
                                    <Table.Row
                                        style={{
                                            display: this.state.openInformation && this.state.currentId === item.id
                                                ? ''
                                                : 'none',
                                        }}>
                                        <Table.Cell colSpan="10">
                                            <Fade duration={1000}>
                                                <React.Fragment>
                                                    {
                                                        (() => {
                                                            switch (this.state.informationState) {
                                                                case 'uploadError':
                                                                    return <Message negative>
                                                                        <li>{this.state.uploadError_Message.storageProvider}</li>
                                                                        <li>{this.state.uploadError_Message.message}</li>
                                                                        <li>{this.state.uploadError_Message.folderType}</li>
                                                                        <li>{this.state.uploadError_Message.fileType}</li>
                                                                    </Message>;
                                                                case 'configurationError':
                                                                    return <Message negative className="heavy-error">
                                                                        <li>{this.state.configurationError_Message.type}</li>
                                                                        <li>{this.state.configurationError_Message.message}</li>
                                                                        <li>{this.state.configurationError_Message.stackTrace}</li>
                                                                    </Message>;
                                                                case 'info':
                                                                    return <Message info>
                                                                        <ul>
                                                                            {this.state.statsArray === null ?
                                                                                <li> This file contains no
                                                                                    blocks </li> :
                                                                                (this.state.statsArray.map(item => {
                                                                                    return <li>{item}</li>
                                                                                }))}
                                                                        </ul>
                                                                    </Message>;
                                                                default:
                                                                    return null
                                                            }
                                                        })()
                                                    }
                                                </React.Fragment>
                                            </Fade>
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>

                            })
                            }
                        </Table.Body>)
                    }
                </Table>


                <Grid.Column style={{paddingBottom: '2em'}}>
                    <Pagination
                        activePage={pageNumber}
                        onPageChange={this.handlePaginationChange}
                        totalPages={totalPageNumber}
                    />
                </Grid.Column>


                <Modal size={'mini'} open={openRetryModal} onClose={this.closeRetryModal}>
                    <Modal.Content>
                        <p>{this.state.message}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeRetryModal}>No</Button>
                        <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                onClick={() =>
                                    this.handleRetry(this.state.id, this.state.exportRevitFile, this.state.asNewRequest)
                                }/>
                    </Modal.Actions>
                </Modal>

                <Modal size={'mini'} open={openSkipModal} onClose={this.closeSkipModal}>
                    <Modal.Content>
                        <p>{this.state.message}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeSkipModal}>No</Button>
                        <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                onClick={() =>
                                    this.handleSkip(this.state.id, this.state.skipMessage)
                                }/>
                    </Modal.Actions>
                </Modal>

                <Modal size={'large'} open={showRequest} onClose={this.closeRequestModal}>
                    <Modal.Content>
                        <pre>{this.state.json}</pre>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={
                            this.closeRequestModal}>Close</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    }
}

export default Dashboard
