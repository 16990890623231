import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Button, Divider, Dropdown, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import Import from './Import'
import Project from './Project'

class Choice extends Component {

	constructor (props) {
		super(props)
		this.state = {
			page: 'home',
			loading: true,
			projects: [],
			parsedProjects: [],
			button: false,
			value: '',
		}
	}

	componentDidMount () {
		this.fetchProjects()
		this.interval = setInterval(() => {
			this.fetchProjects()
			return this.setState({ time: Date.now() })
		}, 5000)
	}

	componentWillUnmount () {
		clearInterval(this.interval)
	}

	fetchProjects () {
		let self = this

		fetch(`${process.env.REACT_APP_API_BASE_URL}/project/all`).
			then(response => response.json()).
			then(function (data) {
				self.setState({ projects: data }, () => {
					self.setState({ loading: false }, () => {
						self.parseProject()
					})
				})
			}).
			catch(error => console.log(error))
	}

	parseProject = () => {
		const array = []
		const data = this.state.projects.projects
		for (let i = 0; i < data.length; i++) {
			array.push(
				{ text: data[i], key: data[i], value: data[i] },
			)
		}
		this.setState({ parsedProjects: array }, () => {
		})
	}

	renderButton = (e, { value }) => {
		if (value !== '') {
			this.setState({ value: value }, () => {
				this.setState({ button: true })
			})
		} else {
			this.setState({ value: value }, () => {
				this.setState({ button: false })
			})
		}

	}

	render () {
		const projectProps = {
			pathname: `/import/${this.state.value}`,
		}

		return (
			<div>
				<Segment style={{ margin: 'auto', maxWidth: '800px', marginTop: '25vh' }} placeholder>
					<Grid columns={2} stackable textAlign='center'>
						<Divider vertical>Or</Divider>

						<Grid.Row verticalAlign='middle'>
							<Grid.Column>
								<Header icon>
									<Icon name='file outline'/>
									Select a new Project
								</Header>
								<Dropdown
									onChange={this.renderButton}
									clearable
									fluid
									search
									selection
									value={this.state.value}
									options={this.state.parsedProjects}
									placeholder='Select a Project'
								/>
							</Grid.Column>
							<Grid.Column>
								<Header icon>
									<Icon name='edit outline'/>
									Create a new Project
								</Header>
								<NavLink to="project"> <Button> Create a new Project</Button></NavLink>
							</Grid.Column>
						</Grid.Row>
					</Grid>

				</Segment>
				<div style={{
					display: 'flex', flexDirection: 'row', paddingTop: '50px',
					maxWidth: '800px', margin: 'auto', justifyContent: 'space-evenly',
				}}>
					{this.state.button ?
						(
							<div>
								<NavLink to={projectProps}><Button size={'big'}> Create a new element</Button></NavLink>
								<NavLink to={projectProps}><Button size={'big'}>View elements</Button></NavLink>
							</div>

						) :
						(	<div>
								<Button disabled size={'big'}> Create a new element</Button>
								<Button disabled size={'big'}>View elements</Button>
							</div>
						)
					}
				</div>
			</div>

		)
	}
}

export default Choice
