import React from 'react';
import {Route, Switch, Link} from 'react-router-dom';
import './App.css';
import Import from "./Components/Import";
import Footer from "./Components/Footer";
import Dashboard from "./Components/Dashboard";
import {Container, Image, Menu,} from 'semantic-ui-react'
import logo from "./Images/form-logo.svg"
import Choice from "./Components/Choice";
import Project from "./Components/Project";


function DashboardChild({match}) {
	return (
		<div>
			<Dashboard projectName={match.params.projectName} pageNumber={match.params.pageNumber}/>
		</div>
	);
}

function ImportChild({match}) {
	return (
		<div>
			<Import projectName={match.params.projectName}/>
		</div>
	);
}

function App() {
  return (
    <div className="App">
	    <Menu  fixed='top' >
		    <Container >
			    <Menu.Item as='a' color="white" header>
				    <Image size='tiny' src={logo} style={{ marginRight: '.5em' }} />
			    </Menu.Item>
			    <Menu.Item as={Link} to='/'>Home</Menu.Item>
			    {/*<Menu.Item as={Link} to='/dashboard/'>Dashboard</Menu.Item>*/}
		    </Container>
	    </Menu>


        <div className="page-content">
        <Switch>
          <Route exact path="/" component={Choice}/>
          <Route exact path="/import/:projectName" component={ImportChild}/>
			<Route exact path="/choice" component={Choice}/>
			<Route exact path="/project" component={Project}/>
			<Route path="/dashboard/:projectName/:pageNumber" component={DashboardChild}/>

        </Switch>
        </div>
        <Footer/>
    </div>
  );
}

export default App;
