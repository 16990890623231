import React, {Component} from 'react';
import {Button, Message, Form} from 'semantic-ui-react'
import {Grid} from "semantic-ui-react/dist/commonjs/collections/Grid";
import {NavLink} from "react-router-dom";

class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            button: false,
            project: "",
            projectValue:"",
            errored: false,
            success: false
        }
    }

    handleChange = (e, {value}) => {
        //If  submit button is clicked do nothing / disable typing into textInput
        if(this.state.success){
            return null
        }
        else{
            this.setState({project: value})
        }
    };

    createNewProject = () => {
        this.setState({project:""});
        this.setState({errored:""});
        this.setState({success:""});
        this.setState({button:""});
    };

        createProject = () => {
            //Set projectValue state to sumbitted value of project state.
            // Prevents alteration of name when user tries to type during error message
        const self = this;
        fetch(`${process.env.REACT_APP_API_BASE_URL}/project`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                projectName: this.state.project
            })
        }).then(function (response) {
                console.log(response.status);
            if (response.status === 409) {
                self.setState({success: false});
                self.setState({errored: true});
            }
            else if (!response.ok) {
                throw new Error(response.statusText);
            }
            else if (response.ok) {
                self.setState({errored: false});
                self.setState({projectValue: ""});

                self.setState({success: true});
            }
        }
            ).catch(error => console.log(error));
        };


    render() {
        const successMessage = `New project ${this.state.project} created`;
        const erroredMessage = `A project with that name already exists`;

        return (
            <div>
                <Form style={{maxWidth: "500px", margin: "auto", marginTop: "25vh"}}>
                    <h2> Create a new Project</h2>
                    {!this.state.success ? (<Form.Field>
                            <Form.Input onChange={this.handleChange} value={this.state.project}
                                        placeholder='enter project name' type="text">
                            </Form.Input>
                        </Form.Field>
                    ): null}

                    {this.state.success ? (<Message positive header={successMessage}
                                                   content="Return to choice screen or create another project"/>
                    ): null}

                    {this.state.errored ? (<Message negative header={erroredMessage}
                                                   content="Please choose another name"/>
                    ): null}

                    {!this.state.success ? (<Button style={{marginTop: "20px"}} onClick={this.createProject} type='submit'>Submit</Button> ): null}
                    {this.state.success ? (<Button style={{marginTop: "20px"}} onClick={this.createNewProject}
                                                   type='submit'>Create another project</Button> ): null}
                    <NavLink to="/choice"> <Button style={{marginTop: "20px"}}>Return to choice</Button></NavLink>
                </Form>
            </div>
        );
    }
}

export default Project;
